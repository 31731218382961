import { Fragment, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useParams, useNavigate } from 'react-router-dom'
import axios from 'axios';
import { REACT_APP_API_HOST } from './environmentVariables';


function CouponCode({ locationInfo }) {
    const [logoLoading, setLogoLoading] = useState(true)

    let { couponId } = useParams();
    const navigate = useNavigate()

    const fetchCouponInfo = async () => {
        let url = REACT_APP_API_HOST + `/public/coupons/` + couponId + '/code'
        return await axios.get(url).then((response) => {
            return response
        });
    };

    const { isLoading, data } = useQuery(["coupon_code", couponId], fetchCouponInfo);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            function handleResize() {
                let vh = window.innerHeight * 0.01
                if (vh < (document.querySelector('div').scrollHeight * 0.01)) {
                    vh = 6.67
                }

                document.documentElement.style.setProperty('--vh', `${vh}px`)
            }

            window.addEventListener("resize", handleResize);

            handleResize();

            return () => window.removeEventListener("resize", handleResize);
        }
    });

    useEffect(() => {
        if (locationInfo?.logo_url === null || locationInfo?.logo_url === '') {
            setLogoLoading(false)
        }
    }, [])

    useEffect(() => {
        if (data?.data?.status === 'NOT_REDEEMED') {
            navigate('/' + couponId)
        }
    }, [data])

    return (
        <>
            <div className="w-full h-screen bg-gray-100 flex justify-center items-center">
                <div className='w-full max-w-lg'>
                    {!isLoading ? (<>
                        {locationInfo !== null && (locationInfo.logo_url !== null && locationInfo.logo_url !== '') ? (
                            <div className={`w-full flex justify-center pb-2 `}>
                                <img
                                    className={`h-36 w-auto ${logoLoading ? 'invisible' : ''}`}
                                    src={locationInfo.logo_url}
                                    alt="Workflow"
                                    onLoad={() => {
                                        setLogoLoading(false)
                                    }}
                                />
                            </div>
                        ) : null}
                        {locationInfo !== null && locationInfo.name !== null && (!logoLoading) ? (
                            <>
                                {data?.data?.status == 'AVAILABLE' ? <>
                                    {/* {data?.data?.code */}
                                    <div>
                                        <div className="w-full flex justify-center font-semibold xxs:text-2xl text-xl pb-4 mt-2">
                                            <div>{locationInfo?.name}</div>
                                        </div>
                                        <div className=" text-center mb-3">
                                            <div as="h3" className="xxs:text-2xl text-xl mt-6 leading-6 text-gray-900 ">
                                                Get {data?.data?.amount}{data?.data?.type === 'percent' ? '%' : null}{data?.data?.type === 'dollar' ? '$' : null} off your next order
                                            </div>
                                        </div>
                                        <div className=" text-center bg-white mx-4 rounded-t-lg mt-8">
                                            <div as="h3" className="xxs:text-2xl text-xl  text-gray-700 py-2">
                                                Code
                                            </div>
                                            <div as="h3" className="xxs:text-2xl font-bold text-xl rounded-b-lg text-gray-100 bg-gray-800 py-2">
                                                5OFF
                                            </div>
                                        </div>
                                    </div>
                                </> : null}
                                {data?.data?.status == 'REDEEMED_EXPIRED' ? (
                                    <div>
                                        <div className="w-full flex justify-center font-semibold xxs:text-2xl text-xl pb-4 mt-2">
                                            <div>{locationInfo?.name}</div>
                                        </div>
                                        <div className=" text-center mb-3">
                                            <div as="h3" className="xxs:text-2xl text-xl mt-6 leading-6 text-gray-900 ">
                                                This coupon has already been redeemed
                                            </div>
                                        </div>

                                    </div>
                                ) : null}
                            </>
                        ) : null}

                    </>) : (<div className='flex justify-center mt-12'>
                        <svg className="inline mr-2 w-12 h-12 text-gray-200 animate-spin dark:text-gray-600 fill-gray-200" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                        </svg>
                    </div>)}
                </div>
            </div>

        </>)
}

export default CouponCode