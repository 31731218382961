import './App.css';
import { useState, useEffect } from 'react'
import { Routes, Route, useParams } from "react-router-dom";
import Redeem from './Redeem';
import axios from 'axios';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query'
import { REACT_APP_API_HOST } from './environmentVariables';

import CouponCode from './CouponCode';

export const queryClient = new QueryClient()

function App() {
  const [locationInfo, setLocationInfo] = useState(null)
  const [brandColor, setBrandColor] = useState('#6366f1')


  useEffect(() => {
    const getDatas = async () => {
      const response = await axios.get(REACT_APP_API_HOST + "/restaurant/public/location/A12B12C121");
      const data = await response
      setLocationInfo(data.data)
      setBrandColor(data.data.brand_color)
    }
    getDatas()

  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <>
        <Routes>
          <Route path="/:couponId" element={<Redeem locationInfo={locationInfo} brandColor={brandColor} />} />
          <Route path="/:couponId/code" element={<CouponCode locationInfo={locationInfo} />} />
        </Routes>
      </>
    </QueryClientProvider>
  );
}

export default App;
