import './App.css';
import { Fragment, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import {
    TicketIcon
} from '@heroicons/react/24/outline'
import axios from 'axios';
import { Dialog, Transition } from '@headlessui/react'
import { useQuery } from 'react-query'
import { REACT_APP_API_HOST } from './environmentVariables';


function Redeem({ locationInfo, brandColor }) {
    const [logoLoading, setLogoLoading] = useState(false)
    const [open, setOpen] = useState(false)

    let { couponId } = useParams();
    const navigate = useNavigate()

    const fetchCouponInfo = async () => {
        let url = REACT_APP_API_HOST + `/public/coupons/` + couponId + '/info'
        return await axios.get(url).then((response) => {
            return response
        });
    };

    const { isLoading, data } = useQuery(["coupon_info", couponId], fetchCouponInfo);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            function handleResize() {
                let vh = window.innerHeight * 0.01
                if (vh < (document.querySelector('div').scrollHeight * 0.01)) {
                    vh = 6.67
                }

                document.documentElement.style.setProperty('--vh', `${vh}px`)
            }

            window.addEventListener("resize", handleResize);

            handleResize();

            return () => window.removeEventListener("resize", handleResize);
        }
    });

    useEffect(() => {
        if (data?.data?.redeemed_at) {
            navigate('/' + couponId + '/code', { couponId: couponId })
        }
    }, [data]);

    useEffect(() => {
        if (locationInfo?.logo_url === null || locationInfo?.logo_url === '') {
            setLogoLoading(false)
        }
    }, [])

    const handleRedeem = async () => {
        let url = REACT_APP_API_HOST + `/public/coupons/` + couponId + '/redeem'
        const result = await axios.post(url);
        // setOpen(false)
        navigate('/' + couponId + '/code', { couponId: couponId })
    };

    return (
        <>
            <div className={"w-full h-screen bg-gray-100 flex justify-center items-center"}>
                <div className='w-full max-w-lg'>
                    <div >
                        {!isLoading ? (
                            <>
                                {locationInfo !== null && (locationInfo.logo_url !== null && locationInfo.logo_url !== '') ? (
                                    <div className={`w-full flex justify-center pb-2 `}>
                                        <img
                                            className={`h-36 w-auto ${logoLoading ? 'invisible' : ''}`}
                                            src={locationInfo.logo_url}
                                            alt="Workflow"
                                            onLoad={() => {
                                                setLogoLoading(false)
                                            }}
                                        />
                                    </div>
                                ) : null}
                                {locationInfo !== null && locationInfo.name !== null && (!logoLoading) ? (
                                    <>
                                        <div className="w-full flex justify-center font-semibold xxs:text-2xl text-xl pb-4 mt-2">
                                            <div>{locationInfo.name}</div>
                                        </div>
                                        <div className=" text-center mb-3">
                                            <div as="h3" className="xxs:text-2xl text-xl mt-6 leading-6 text-gray-900 ">
                                                Get {data?.data?.amount}{data?.data?.type === 'percent' ? '%' : null}{data?.data?.type === 'dollar' ? '$' : null} off your next order
                                            </div>
                                        </div>
                                        <div className="mx-8 mt-8 ">
                                            <button
                                                type="button"
                                                className="mr-8 inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none sm:col-start-2 sm:text-sm"
                                                style={{ backgroundColor: brandColor }}
                                                onClick={() => setOpen(true)}
                                            >
                                                Redeem
                                            </button>
                                            {data?.data?.additional_info ? (
                                                <div className="max-w-sm text-sm font-light text-gray-500 pt-5 flex justify-center items-center">
                                                    {data?.data?.additional_info}
                                                </div>
                                            ) : null}

                                        </div>

                                    </>
                                ) : (<></>)}
                            </>
                        ) : (<div className='flex justify-center mt-12'>
                            <svg className="inline mr-2 w-12 h-12 text-gray-200 animate-spin dark:text-gray-600 fill-gray-200" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                            </svg>
                        </div>)}



                    </div>

                </div>
            </div>
            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={setOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                    <div>
                                        <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-indigo-100">
                                            <TicketIcon className="h-6 w-6 text-indigo-600" aria-hidden="true" />
                                        </div>
                                        <div className="mt-3 text-center sm:mt-5">
                                            <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                                                Redeem coupon?
                                            </Dialog.Title>
                                            <div className="mt-2">
                                                <p className="text-sm text-gray-500">
                                                    Once you redeem, you will have 24 hours to use the coupon code.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                        <button
                                            type="button"
                                            className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none sm:col-start-2 sm:text-sm"
                                            onClick={() => handleRedeem()}
                                            style={{ backgroundColor: brandColor }}
                                        >
                                            Redeem
                                        </button>
                                        <button
                                            type="button"
                                            className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                                            onClick={() => setOpen(false)}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    );
}

export default Redeem;
